import React, { useState } from 'react';
import { LoginButton } from './services/authentication.service';
import { getNixieState } from './services/backend.service';


function FooledByRandomness() {
  const [nixieState, setNixieState] = useState(null);
  const [errorText, setErrorText] = useState(null);

  // useEffect(() => {
  //   getNixieState().then(setNixieState).catch((e) => { setErrorText(e.toString()) });
  // }, []);

  return (
    <div className="uk-width-1-3@m uk-container-center uk-align-center">
      <h1 className="uk-heading-large uk-heading-line">Fooled By Randomness</h1>
      <hr className="uk-divider-icon" />
      {
        errorText ?
        <div className="uk-alert uk-alert-danger">
          {errorText}
          <button className="uk-alert-close" onClick={() => setErrorText(null)}>
            X
          </button>
        </div> :
        null
      }
      <div className="uk-text-center">
        <LoginButton />
      </div>
      <br></br>
      <button
        className="uk-button uk-button-default uk-border-pill uk-box-shadow-small uk-box-shadow-hover-medium uk-width-1-1"
        onClick={() => getNixieState().then(setNixieState).catch((e) => { setErrorText(e.toString()) })}
        >
        Get nixie_state.
      </button>
      <pre>{JSON.stringify(nixieState, null, " ")}</pre>
    </div>
  );
}

export default FooledByRandomness;