import { authHeader } from '../helpers/authHeader';
import { authenticationService } from './authentication.service';


function handleResponse(response: Response) {
  console.log(response);

  if (!response.text) {
    console.log(response.statusText);
    return Promise.reject(response.statusText);
  }

  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      console.log(error);
      return Promise.reject(error);
    }

    return data;
  });
}

export function getTestValue(): Promise<any> {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/test`, requestOptions)
    .then(handleResponse);
}

export function getNixieState(): Promise<any> {
  const requestOptions = { method: 'GET', headers: authHeader() };
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/nixie_state`, requestOptions)
    .then(handleResponse);
}

export interface FoldInput {
  name: string;
  sequence: string;
}

export interface Fold extends FoldInput {
  id: number | null;
  state: string | null;
}

export interface FoldPdb {
  pdb_string: string;
}

export function getFolds(): Promise<Array<Fold>> {
  const requestOptions = { method: 'GET', headers: authHeader() };

  var url = `${process.env.REACT_APP_BACKEND_URL}/api/fold`;
  // if (state) {
  //   url = url + '?' + new URLSearchParams({
  //     state: state
  //   });
  // }
  return fetch(url, requestOptions).then(handleResponse);
}

export function postFold(new_fold: FoldInput): Promise<any> {
  var headers = authHeader();
  headers.set('Content-Type', 'application/json');
  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(new_fold)
  };
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/fold`, requestOptions)
    .then(handleResponse);
}

export function getFold(fold_id: number): Promise<Fold> {
  const requestOptions = { method: 'GET', headers: authHeader() };

  var url = `${process.env.REACT_APP_BACKEND_URL}/api/fold/${fold_id}`;
  return fetch(url, requestOptions).then(handleResponse);
}

export function getFoldPdb(fold_id: number, model_number: number): Promise<FoldPdb> {
  const requestOptions = { method: 'GET', headers: authHeader() };

  var url = `${process.env.REACT_APP_BACKEND_URL}/api/fold_pdb/${fold_id}/${model_number}`;
  return fetch(url, requestOptions).then(handleResponse);
}