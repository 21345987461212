import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UIkit from 'uikit';
import { authenticationService, LoginButton } from './services/authentication.service';
import { Fold, getFolds, postFold } from './services/backend.service';


function make_fold_table(folds: Fold[], show_state: boolean) {
  console.log(folds);
  return <table className="uk-table uk-table-hover" style={{tableLayout: 'fixed'}}>
    <thead>
      <tr>
        <th className="uk-table-small">Name</th>
        <th className="uk-table-large">Sequence</th>
        { show_state ? <th className="uk-table-small">State</th> : null }
      </tr>
    </thead>
    <tbody>
      {[...folds].map((fold) => {
        return <tr key={fold.name}>
          <td><Link to={"/jbeifolds/" + fold.id}>{fold.name}</Link></td>
          <td style={{overflow: 'auto'}}>{fold.sequence}</td>
          { show_state ? <td>{fold.state}</td> : null }
        </tr>
      })}
    </tbody>
  </table>;
}


function JbeiFolds() {
  const [showQueue, setShowQueue] = useState(false);
  const [showFinished, setShowFinished] = useState(false);
  const [queuedFolds, setQueuedFolds] = useState<Fold[] | null>(null);
  const [finishedFolds, setFinishedFolds] = useState<Fold[] | null>(null);
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    if (authenticationService.currentJwtStringValue) {
      getFolds().then((folds) => {
        console.log(folds);
        var queued = [];
        var finished = [];
        for (var fold of folds) {
          if (fold.state === 'QUEUED') {
            queued.push(fold);
          } else {
            finished.push(fold);
          }
        }
        setQueuedFolds(queued);
        setFinishedFolds(finished);
      }).catch((e) => { setErrorText(e.toString()) })
    }
  }, [authenticationService.currentJwtString]);

  function addSequence() {
    UIkit.modal.prompt('Fold Name (exclude index prefix and special characters):', 'namE').then((name) => {
      if (!name) {
        return;
      }
      UIkit.modal.prompt('Fold Sequence:', 'MHAGYSHHHHH').then((sequence) => {
        if (!sequence) {
          return;
        }
        UIkit.modal.confirm('Are you sure you want to fold ' + name + '? It may cost a few dollars.').then((choice) => {
          const new_fold = {
            name: name,
            sequence: sequence,
          };
          console.log('Shipping new fold ');
          console.log(new_fold);
          postFold(new_fold).catch((e) => { setErrorText(e.toString()) });
        })
      });
    });
  }

  return (
    <div>
      <nav className="uk-navbar" uk-navbar style={{background: 'linear-gradient(to left, #28a5f5, #1e87f0)'}}>
        <div className="uk-navbar-left">
          <a href="/jbeifolds" className="uk-navbar-item uk-logo" style={{color: '#fff'}}>JBEI Folds</a>
        </div>

        <div className="uk-navbar-right uk-navbar-item uk-active " style={{color: '#fff'}}>
          <LoginButton />
        </div>
      </nav>

      <div className="uk-width-2-3@m uk-container-center uk-align-center">
        {
          errorText ?
          <div className="uk-alert uk-alert-danger">
            {errorText}
            <button className="uk-alert-close" onClick={() => setErrorText(null)}>
              X
            </button>
          </div> :
          null
        }

        {
          authenticationService.currentJwtStringValue ?
          <div>
            {/* <hr className="uk-divider-icon" /> */}
            <div>
              <h3>Queued Folds</h3>
              <div className="uk-button-group uk-width-1-1 uk-box-shadow-small uk-box-shadow-hover-medium uk-margin-small-left uk-margin-small-right">
                <button
                className="uk-button uk-button-default uk-width-1-1"
                onClick={() => setShowQueue(!showQueue)}>
                  {showQueue ? 'Hide' : 'Show'}
                </button>
                <button className="uk-button uk-button-default" onClick={() => {addSequence();}}>
                  +
                </button>
              </div>
              {
                showQueue && queuedFolds ?
                make_fold_table(queuedFolds, true) :
                null
              }
            </div>

            {/* <hr className="uk-divider-icon" /> */}

            <br></br>
            <div>
              <h3>Finished Folds</h3>
              <button
              className="uk-button uk-button-default uk-box-shadow-small uk-box-shadow-hover-medium uk-width-1-1 uk-margin-small-left uk-margin-small-right"
              onClick={() => setShowFinished(!showFinished)}>
                {showFinished ? 'Hide' : 'Show'}
              </button>
              {
                showFinished && finishedFolds ?
                make_fold_table(finishedFolds, true) :
                null
              }
            </div>
          </div> :
          null
        }
      </div>
    </div>

  );
}

export default JbeiFolds;