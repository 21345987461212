import React, { Component, createRef, RefObject } from 'react';
// import { Stage, Component as NglComponent, StructureRepresentationDescriptor, StructureComponent } from 'react-ngl';
import { isNullishCoalesce } from 'typescript';
import { LoginButton } from './services/authentication.service';
import { Fold, FoldPdb, getFold, getFoldPdb } from './services/backend.service';
const NGL = require('./../node_modules/ngl/dist/ngl');


interface JbeiFoldProps {
  match: { params: any };
}

interface JbeiFoldState {
  foldData: Fold | null;
  errorText: string | null;
  pdb: FoldPdb | null;
  stageRef: RefObject<any>;
  pdb_file: string;
}


class JbeiFold extends Component<JbeiFoldProps, JbeiFoldState> {
  
  constructor(props: JbeiFoldProps) {
    super(props);
    const fold_id_folder = String(this.props.match.params.foldId).padStart(5, '0');
    const pdb_file = `https://storage.googleapis.com/jbeifolds/out/${fold_id_folder}/ranked_0.pdb`;
    this.state = {
      foldData: null,
      errorText: null,
      pdb: null,
      stageRef: React.createRef(),
      pdb_file: pdb_file
    }
  }

  componentDidMount() {
    getFold(this.props.match.params.foldId).then((new_fold_data) => {
      this.setState({foldData: new_fold_data});

      return getFoldPdb(this.props.match.params.foldId, 0).then((pdb) => {
        console.log(pdb.pdb_string)
        this.setState({pdb: pdb});

        if (!this.state.foldData || !this.state.foldData.id) {
          return;
        }

        console.log(`PDB is ${pdb.pdb_string.length} characters long.`);
        var stage = new NGL.Stage( "viewport", {backgroundColor:'white'});
        
        stage.loadFile(this.state.pdb_file).then(function (o: any) {
          o.addRepresentation("cartoon")
          o.autoView()
        });
      })
    }).catch((e) => {
      this.setState({errorText: e.toString()});
    });
  }

  render() {
    return (
      <div>
        <nav className="uk-navbar" uk-navbar style={{background: 'linear-gradient(to left, #28a5f5, #1e87f0)'}}>
          <div className="uk-navbar-left">
            <a href="/jbeifolds" className="uk-navbar-item uk-logo" style={{color: '#fff'}}>JBEI Folds</a>
          </div>

          <div className="uk-navbar-right uk-navbar-item uk-active " style={{color: '#fff'}}>
            <LoginButton />
          </div>
        </nav>

        <div className="uk-width-2-3@m uk-container-center uk-align-center">
          <h2 className="uk-heading-line uk-margin-left uk-margin-right uk-text-center"><b>{this.state.foldData ? this.state.foldData.name : 'Loading...'}</b></h2>
          {/* <hr className="uk-divider-icon" /> */}

          {
            this.state.errorText ?
            <div className="uk-alert uk-alert-danger">
              {this.state.errorText}
              <button className="uk-alert-close" onClick={() => this.setState({errorText: null})}>
                X
              </button>
            </div> :
            null
          }

          <pre>{JSON.stringify(this.state.foldData, null, "  ")}</pre>

          {this.state.foldData ? (this.state.pdb ? `PDB file is ${this.state.pdb.pdb_string.length} characters long.` : 'Loading PDB...') : null}
          <a className="uk-button uk-button-default uk-margin-left" href={this.state.pdb_file}>Download PDB</a>


          <div id="viewport" style={{width:'700px', height:'500px'}} ref={this.state.stageRef}></div>
        </div>
      </div>
    );
  }
}

export default JbeiFold;