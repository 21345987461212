import React, { Component, RefObject } from 'react';
import './DvdPlayer.scss';
import dvd from './dvd-logo.png';
// import remote_control from './remote-control.png';
import randomcolor from 'randomcolor';
import 'react-tiny-fab/dist/styles.css';


interface SizeParams {
  BORDER_PX: number;
  BUFFER_PX: number;
  CONTROL_BAR_PX: number;
  BUTTON_BUFF_PX: number;
  TITLE_FONT_SIZE: number;
  DVD_SIZE: number;
}


class DvdPlayer extends Component<{}, {}> {
  largeScreenParams: SizeParams = {
    BORDER_PX: 50, 
    BUFFER_PX: 30,
    CONTROL_BAR_PX: 100,
    BUTTON_BUFF_PX: 7,
    TITLE_FONT_SIZE: 50,
    DVD_SIZE: 220,
  }
  smallScreenParams: SizeParams = {
    BORDER_PX: 20, 
    BUFFER_PX: 20,
    CONTROL_BAR_PX: 70,
    BUTTON_BUFF_PX: 7,
    TITLE_FONT_SIZE: 30,
    DVD_SIZE: 120
  }
  params: SizeParams;

  canvasRef: RefObject<HTMLCanvasElement> | null;
  cWidth: number = 0;
  cHeight: number = 0;
  
  // Useful: https://css-tricks.com/bounce-element-around-viewport-in-css/
  dvdImg: HTMLImageElement | null = null;
  dvdX: number = 300;
  dvdY: number = 200;
  dvdDx: number = 2;
  dvdDy: number = 1.5;
  dvdColor: string = "#FF0000";

  constructor(props: {}) {
    super(props);
    this.canvasRef = React.createRef();
    this.params = this.largeScreenParams;
  }

  render() {
    // Remote icon
    // <Fab icon={<img src={remote_control} alt="menu"></img>}>
    return (
      <div className="DvdPlayer">
        <div className="border">
        <div className="tvBackdrop"><canvas ref={this.canvasRef}></canvas></div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // if (this.canvasRef == null || this.canvasRef.current == null) {
    //   return;
    // }
    // const canvas = this.canvasRef.current;
    // canvas.style.background = 'hsl(0, 0%, 30%)';//'#282828'

    this.dvdImg = new Image();
    this.dvdImg.onload = () => {
      this.animate();
    }
    this.dvdImg.src = dvd;

    this.animate();
  }

  animate() {
    if (this.canvasRef == null || this.canvasRef.current == null) {
      console.log('canvasref is null');
      return;
    }

    const canvas = this.canvasRef.current;
    this.cWidth  = window.innerWidth;// - p.BORDER_PX * 2;
    this.cHeight = window.innerHeight;// - p.BORDER_PX * 2 - this.CONTROL_BAR_PX;
    canvas.width  = this.cWidth;
    canvas.height = this.cHeight;

    
    if (this.cWidth < 600 || this.cHeight < 700) {
      this.params = this.smallScreenParams;
    } else {
      this.params = this.largeScreenParams;
    }
    
    const c = canvas.getContext("2d");
    if (!c) {
      console.log('c is null.');
      return;
    }
    
    c.filter = 'blur(2px)';
    this.drawDvdLogo(c);
    this.drawBorder(c);
    // this.drawButtons(c);

    requestAnimationFrame(() => this.animate());
  }

  drawDvdLogo(c: CanvasRenderingContext2D): void {
    if (!this.dvdImg) {
      console.log('dvdImg is null.');
      return;
    }
    const dvdSize = this.params.DVD_SIZE;
    
    c.clearRect(0, 0, this.cWidth, this.cHeight);
    c.fillStyle = this.dvdColor;
    c.fillRect(this.dvdX, this.dvdY, dvdSize, dvdSize);
    // In order for image to have right size, we must pass in the fourth and fifth params:
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
    c.drawImage(this.dvdImg, 0, 0, 2400, 2400, this.dvdX, this.dvdY, dvdSize, dvdSize);

    this.dvdX += this.dvdDx;
    this.dvdY += this.dvdDy;

    if (this.dvdX < this.params.BORDER_PX) {
      this.dvdDx = Math.abs(this.dvdDx);
      this.handleDvdCollision();
    } else if (this.dvdX + dvdSize > this.cWidth - this.params.BORDER_PX) {
      this.dvdDx = -Math.abs(this.dvdDx);
      this.handleDvdCollision();
    }
    if (this.dvdY < this.params.BORDER_PX) {
      this.dvdDy = Math.abs(this.dvdDy);
      this.handleDvdCollision();
    } else if (this.dvdY + dvdSize > this.cHeight - this.params.BORDER_PX - this.params.CONTROL_BAR_PX) {
      this.dvdDy = -Math.abs(this.dvdDy);
      this.handleDvdCollision();
    }
  }

  handleDvdCollision() {
    this.dvdColor = randomcolor();
  }

  drawBorder(c: CanvasRenderingContext2D): void {
    // Bezier Curve: https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Drawing_shapes
    c.beginPath();
    const p = this.params;
    const pts = [
      {x: p.BORDER_PX, y: p.BORDER_PX},
      {x: this.cWidth - p.BORDER_PX, y: p.BORDER_PX},
      {x: this.cWidth - p.BORDER_PX, y: this.cHeight - p.BORDER_PX - p.CONTROL_BAR_PX},
      {x: p.BORDER_PX, y: this.cHeight - p.BORDER_PX - p.CONTROL_BAR_PX},
      {x: p.BORDER_PX, y: p.BORDER_PX},
    ];
    const deltas = [
      {x: 0, y: -p.BUFFER_PX},
      {x: p.BUFFER_PX, y: 0},
      {x: 0, y: p.BUFFER_PX},
      {x: -p.BUFFER_PX, y: 0},
    ]
    c.moveTo(pts[0].x, pts[0].y);
    for (var ii = 0; ii < deltas.length; ++ii) {
      const midway_x = (pts[ii + 1].x + pts[ii].x) / 2.0 + deltas[ii].x;
      const midway_y = (pts[ii + 1].y + pts[ii].y) / 2.0 + deltas[ii].y;
      c.quadraticCurveTo(midway_x, midway_y, pts[ii + 1].x, pts[ii + 1].y);
    }
    c.lineWidth = 3;
    c.strokeStyle = 'hsl(0, 0%, 90%)';
    c.stroke();
    
    c.rect(this.cWidth, 0, -this.cWidth, this.cHeight);
    c.fillStyle = 'hsl(0, 0%, 10%)';
    c.fill('evenodd');


    // Text styling: https://www.w3schools.com/tags/canvas_font.asp
    c.font = `600 ${this.params.TITLE_FONT_SIZE}px Verdana`;
    c.textAlign = "center";
    // https://www.w3schools.com/tags/canvas_textbaseline.asp
    c.textBaseline = "middle";
    const text = `JACOBERTS`;
    const textx = this.cWidth / 2;
    const texty = this.cHeight - p.CONTROL_BAR_PX / 2 - 10;

    // A couple gold variants: https://www.rapidtables.com/web/color/Gold_Color.html
    c.fillStyle = "#D4AF37";
    c.fillText(text, textx, texty);
    c.strokeStyle = '#FFDF00';
    c.lineWidth = 2;
    c.strokeText(text, textx, texty);
  }
}


export default DvdPlayer;
